import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'
import {
  getRemoteConfig,
  getValue as getValueLibrary,
  fetchAndActivate as fetchAndActivateLibrary,
  getAll as getAllLibrary
} from 'firebase/remote-config'

const firebaseConfig = {
  apiKey: import.meta.env.VITE_APP_FIREBASE_APIKEY,
  authDomain: import.meta.env.VITE_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: import.meta.env.VITE_APP_FIREBASE_DATABASEURL,
  projectId: import.meta.env.VITE_APP_FIREBASE_PROJECTID,
  storageBucket: import.meta.env.VITE_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: import.meta.env.VITE_APP_FIREBASE_SENDERID,
  appId: import.meta.env.VITE_APP_FIREBASE_APPID,
  measurementId: import.meta.env.VITE_APP_FIREBASE_MEASUREMENTID
}

const app = initializeApp(firebaseConfig)
const database = getDatabase(app)
const remoteConfig = getRemoteConfig(app)

remoteConfig.settings.minimumFetchIntervalMillis = 3600000
remoteConfig.defaultConfig = {} //TODO something

const fetchAndActivate = () => fetchAndActivateLibrary(remoteConfig)
const getValue = (key: string) => getValueLibrary(remoteConfig, key)
const getFeatureFlagValue = (key: string) => getValue(key).asBoolean()
const getAll = () => getAllLibrary(remoteConfig)
const getAllFeatureFlagValues = () =>
  Object.fromEntries(
    Object.entries(getAll()).map((e) => [e[0], e[1].asBoolean()])
  )

export {
  database,
  remoteConfig,
  fetchAndActivate,
  getValue,
  getFeatureFlagValue,
  getAll,
  getAllFeatureFlagValues
}
