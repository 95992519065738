﻿﻿import { defineStore } from 'pinia'
import ErrorHelper from '@/exports/error'
import type {
  IDownloadAttachmentRequest,
  IGetAttachmentsRequest
} from '@/models/interfaces'
import type { IAttachmentsState } from '@/models/stores/attachments'
import helpers from '../exports/helper'
import httpClient from '@/httpClient'
import { Attachment } from '@/models/models'
import type { IGetAttachmentForDownloadRequest } from '@/models/interfaces'
import { useVaultStore } from './VaultStore'

const initialState = (): IAttachmentsState => ({
  attachments: [new Attachment(), new Attachment(), new Attachment()],
  files: [],
  count: 0,
  showDropzone: false,
  initialFileUploaded: false,
  loadMoreItems: false,
  fileErrorMessage: ''
})

const paths: string[] = []

export const useAttachmentsStore = defineStore('attachments', {
  state: initialState,
  actions: {
    reset() {
      Object.assign(
        this.$state,
        helpers.omit(initialState(), paths as (keyof IAttachmentsState)[])
      )
    },
    async setShowDropzone(showDropzone: boolean) {
      this.showDropzone = showDropzone
    },
    async setInitialFileUploaded(initialFileUploaded: boolean) {
      this.initialFileUploaded = initialFileUploaded
    },
    async fetchAttachments(payload: IGetAttachmentsRequest) {
      try {
        const url = `/web/api/Attachment/GetAttachmentsByCase?page=${payload.page}&searchTerm=${payload.searchTerm}`

        const response = await httpClient.get(encodeURI(url), {
          timeout: 0,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        if (!response?.data?.success)
          throw new Error(response?.data?.errorMessage)
        const _attachments =
          payload.page == 1
            ? response.data.value.attachments
            : [...this.attachments, ...response.data.value.attachments]

        this.attachments = _attachments
        this.count = response?.data.value.count
        this.loadMoreItems = _attachments.length < this.count
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchAttachments')
      }
    },
    async getAttachmentForDownload(payload: IGetAttachmentForDownloadRequest) {
      try {
        let url = `/web/api/Attachment/GetAttachmentForDownload?SpecifyFileName=${payload.specifyFileName}&ItemID=${payload.itemID}&ThreadID=${payload.threadID}&IsFromLibrary=${payload.isFromLibrary}`
        // '#' is not a restricted filename character but will break the request
        url = encodeURI(url).replace(/#/g, '')
        const response = await httpClient.get(url, {
          responseType: 'blob',
          timeout: 240000
        })
        if (response?.headers['content-type'] != 'text/plain; charset=utf-8') {
          const fileURL = window.URL.createObjectURL(
            new Blob([response.data], {
              type: response.headers['content-type']
            })
          )
          const fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', payload?.specifyFileName)
          document.body.appendChild(fileLink)

          fileLink.click()
          // display successful download toast

          return true
        } else {
          // display error on download toast
          const err = new Error('Download error')
          ErrorHelper.handleError(
            err,
            'getAttachmentForDownload',
            true,
            'An error has occurred with your download. Please check your wifi connection and try again.'
          )

          return false
        }
      } catch (e) {
        ErrorHelper.handleError(
          e,
          'getAttachmentForDownload',
          true,
          'An error has occurred with your download. Please check your wifi connection and try again.'
        )

        return false
      }
    },
    async fetchAttachmentSasUrl(attachmentId: number) {
      const url = `/mobile/api/attachment/getsasurl?attachmentID=${attachmentId}`

      try {
        const result = await httpClient.get(url, {
          params: {
            attachmentID: attachmentId
          }
        })

        return result.data
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchAttachmentSasUrl')
      }
    },
    async getJournalAttachmentForDownload(payload: IDownloadAttachmentRequest) {
      try {
        const url = `/web/api/Attachment/GetJournalAttachmentForDownload?AttachmentId=${payload.attachmentId}`

        const response = await httpClient.get(encodeURI(url), {
          responseType: 'blob',
          timeout: 240000
        })
        if (response?.headers['content-type'] != 'text/plain; charset=utf-8') {
          const fileURL = window.URL.createObjectURL(
            new Blob([response.data], {
              type: response.headers['content-type']
            })
          )
          const fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', payload.fileName)
          document.body.appendChild(fileLink)

          fileLink.click()
        } else {
          ErrorHelper.handleError(
            new Error('Download error'),
            'getJournalAttachmentForDownload',
            true,
            'An error has occurred with your download. Please check your wifi connection and try again.'
          )
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'getJournalAttachmentForDownload')
      }
    },
    async addFileMethod(file: File) {
      this.files = [...this.files, file]
    },
    async removeFileMethod(fileIndex: number) {
      this.files.splice(fileIndex, 1)
    },
    async removeAllFiles() {
      this.files = []
    },
    async setFileErrorMessage(msg: string) {
      this.fileErrorMessage = msg
    },
    setFiles(files: File[]) {
      this.files = files
    }
  }
})